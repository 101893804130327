<template>
  <div class="relative flex">
    <div v-if="templatevar.show_sidebar == 1" class="hidden bg-secondary-500 p-4 md:block">
      <router-link
        v-for="childGroup in childGroups" :key="childGroup.url" :to="`/shop/${childGroup.url}`"
        class="flex items-center py-2 font-title text-xl uppercase text-white"
      >
        <i-heroicons-solid:chevron-right class="mr-1" /> {{ childGroup.name }}
      </router-link>
      <div class="flex flex-col gap-2 py-2">
        <div v-if="products.length > 0" class="font-title text-xl font-semibold uppercase text-white">
          Top rated products
        </div>
        <router-link
          v-for="product in products" :key="product.id" :to="`/product/${product.url}`"
          class="flex overflow-hidden"
        >
          <div class="flex w-64 flex-col text-white">
            <div class="text-lg font-semibold">
              {{ product.name }}
            </div>
            <div class="font-semibold" :class="{ 'text-brand-500': product.is_on_special }">
              ${{ product.price.toFixed(2) }} AUD
            </div>
            <div>
              <span class="text-sm text-secondary-200">Or 4 payments of ${{ (product.price / 4).toFixed(2) }} with </span>
              <cgn-brand-afterpay class="inline h-9" />
            </div>
          </div>
          <div>
            <cgn-lazy-image class="aspect-square w-32 rounded-md border border-secondary-300" :image="product.image" />
          </div>
        </router-link>
      </div>
    </div>
    <div class="w-full gap-10 px-4 pb-20 sm:px-6 lg:px-8 lg:pb-28">
      <page-builder-heading v-if="onSpecialProducts.length > 0" :templatevar="{ heading: 'OCTANE APPAREL SALE' }" />
      <shop-product4-row :products="onSpecialProducts" :class="{ 'mx-auto max-w-7xl': templatevar.show_sidebar == 0 }" />
      <page-builder-heading v-if="newProducts.length > 0" :templatevar="{ heading: 'NEW ARRIVALS' }" />
      <shop-product4-row :products="newProducts" :class="{ 'mx-auto max-w-7xl': templatevar.show_sidebar == 0 }" />
    </div>
  </div>
</template>

<script lang="ts">
import { gql } from 'graphql-request'
import { SellProduct } from '~cognito/models/Sell/Product'
import { CognitoGroup } from '~cognito/models/Cognito/Group'
import { $axios } from '~cognito/plugins/axios'

class Templatevars {
  show_sidebar?: number
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})

const products = ref<SellProduct[]>([])
const onSpecialProducts = ref<SellProduct[]>([])
const newProducts = ref<SellProduct[]>([])

const groupDetails = ref<CognitoGroup>(new CognitoGroup())
const childGroups = ref<CognitoGroup[]>([])

async function loadProducts() {
  products.value = []
  onSpecialProducts.value = []
  newProducts.value = []

  // Need the group to get heading etc
  groupDetails.value = await new CognitoGroup().findByUrl('Sell\\Product', 'shop')
  childGroups.value = await groupDetails.value.getChildren(1)

  const data = await $axios.graphql(gql`
  {
  sellProducts(byGroup: "top-rated", first: 3) {
    id
    name
    url
    price
    is_new
    is_on_special
    image(image_aspect: "1x1", image_width: 300) {
      url
      height
      width
    }
  }
  onSpecialProducts: sellProducts(byIsOnSpecial: true, first: 4) {
    id
    url
    image(image_aspect: "1x1", image_width: 300) {
      url
      height
      width
    }
    name
    is_new
    is_on_special
    price
    full_price
  }
  newProducts: sellProducts(first: 4, byIsNew: true) {
    id
    url
    image(image_aspect: "1x1", image_width: 300) {
      url
      height
      width
    }
    name
    is_new
    is_on_special
    price
    full_price
  }
  }
  `)
  products.value = new SellProduct().map(data.sellProducts)
  onSpecialProducts.value = new SellProduct().map(data.onSpecialProducts)
  newProducts.value = new SellProduct().map(data.newProducts)
}
watch(() => props, () => {
  loadProducts()
}, {
  deep: true,
})
onMounted(() => {
  loadProducts()
})
onServerPrefetch(async () => {
  await loadProducts()
})
</script>
