<template>
  <cgn-breadcrumb v-if="parentGroups.length > 1" :links="breadcrumbs" />
  <div class="relative">
    <div class="gap-10 px-4 sm:px-6 lg:px-8">
      <div class="relative mx-auto max-w-7xl">
        <div class="mx-auto mt-10 grid max-w-lg gap-5 md:max-w-none md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <template v-if="childGroups.length > 0">
            <router-link
              v-for="childGroup in childGroups" :key="childGroup.url" :to="`/shop/${childGroup.url}`"
              class="relative aspect-[16/6] overflow-hidden rounded-md border border-secondary-300 bg-black"
            >
              <cgn-lazy-image class="w-full opacity-80" :image="childGroup.image" fixed-width />
              <div class="absolute inset-0 flex flex-col items-center justify-center gap-2 p-6 text-center dark:bg-gray-800">
                <div v-if="false" class="font-title text-2xl font-semibold uppercase text-white">
                  {{ childGroup.name }}
                </div>
              </div>
            </router-link>
          </template>
          <template v-else>
            <router-link
              v-for="product in products" :key="product.slug" :to="`/product/${product.slug}`"
              class="relative flex flex-col overflow-hidden rounded-md border border-secondary-300"
            >
              <svg
                v-if="product.is_new" class="absolute right-[2px] top-[2px] w-[45px]"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.66 45.66"
              >
                <circle cx="22.83" cy="22.83" r="21.26" fill="#f04893" />
                <path
                  d="M13.14 26.84H10.8l-3.46-6.07v6.07H4.99v-9.76h2.35l3.45 6.07v-6.07h2.35v9.76zm8.91-4.14h-3.7v2.33h4.37v1.81H16v-9.76h6.74v1.82h-4.38v2.06h3.7v1.74zm10.57.5l1.12-6.12h2.33L34 26.84h-2.44l-1.31-5.56-1.28 5.56h-2.43l-2.08-9.76h2.34l1.12 6.12 1.34-6.12h2l1.36 6.12zm5.69 2.63c0-.33.12-.6.36-.81s.54-.32.89-.32.66.11.9.32.36.48.36.81-.12.6-.36.81a1.32 1.32 0 0 1-.9.32c-.36 0-.66-.11-.89-.32-.24-.21-.36-.48-.36-.81zm2.18-2.08h-1.83l-.29-6.67h2.41l-.29 6.67z"
                  fill="#fff"
                />
              </svg>
              <svg
                v-if="product.is_on_special" class="absolute right-[2px] top-[2px] w-[45px]"
                :class="product.is_new ? 'top-[50px]' : ''"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.66 45.66"
              >
                <circle cx="22.83" cy="22.83" r="21.26" fill="#6277ca" />
                <path
                  d="M8.64 22.49c-1.1-.32-1.91-.71-2.41-1.17a2.23 2.23 0 0 1-.75-1.71c0-.77.31-1.4.92-1.91s1.41-.75 2.4-.75c.67 0 1.27.13 1.79.39s.93.62 1.22 1.07a2.72 2.72 0 0 1 .43 1.49h-1.29c0-.59-.19-1.06-.56-1.4-.38-.34-.91-.51-1.59-.51-.63 0-1.13.14-1.48.42-.36.28-.53.67-.53 1.16 0 .4.17.74.51 1.01.34.28.91.53 1.72.76s1.45.48 1.9.75.8.59 1.02.96.33.8.33 1.29c0 .79-.31 1.42-.92 1.9-.62.48-1.44.71-2.47.71-.67 0-1.3-.13-1.88-.38s-1.03-.61-1.34-1.06c-.32-.45-.47-.95-.47-1.52h1.29c0 .59.22 1.06.65 1.4s1.02.51 1.75.51c.68 0 1.2-.14 1.56-.42s.54-.65.54-1.13-.17-.85-.5-1.11-.94-.52-1.82-.77zm12.03 1.8h-4.09l-.92 2.55h-1.33l3.73-9.76h1.13l3.73 9.76H21.6l-.93-2.55zm-3.7-1.05h3.32l-1.66-4.56-1.66 4.56zm9.93 2.55h4.62v1.05H25.6v-9.76h1.29v8.71zm12.98-3.46h-4.23v3.46h4.91v1.05h-6.2v-9.76h6.13v1.05h-4.85v3.14h4.23v1.05z"
                  fill="#fff"
                />
              </svg>
              <cgn-lazy-image class="aspect-[4/3] w-full bg-white" :image="product.image" />
              <div class="flex flex-col bg-white p-6 text-center dark:bg-darkbg-500">
                <div class="text-xl font-semibold text-gray-900 dark:text-gray-100">
                  {{ product.name }}
                </div>
                <div class="text-lg font-semibold" :class="{ 'text-brand-500': product.is_on_special }">
                  ${{ product.price.toFixed(2) }} AUD
                </div>
                <div>
                  <span>Or 4 payments of ${{ (product.price / 4).toFixed(2) }} with </span>
                  <cgn-brand-afterpay class="inline h-9" />
                </div>
              </div>
            </router-link>
          </template>
        </div>
        <div v-if="parentGroups.length > 0" class="flex grow justify-center py-5">
          <cgn-pagination :current-page="props.page" :page-count="num_pages" :group="props.group" url-prefix="shop" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { gql } from 'graphql-request'
import { SellProduct } from '~cognito/models/Sell/Product'
import { CognitoGroup } from '~cognito/models/Cognito/Group'
import { $axios } from '~cognito/plugins/axios'

const props = defineProps({
  group: {
    type: String,
    default: 'shop',
  },
  page: {
    type: Number,
    default: 1,
  },
})
const products = ref<SellProduct[]>([])

const groupDetails = ref<CognitoGroup>(new CognitoGroup())
const parentGroups = ref<CognitoGroup[]>([])
const childGroups = ref<CognitoGroup[]>([])
const num_pages = ref(0)
const tag = ref('')

const breadcrumbs = ref([{ name: 'Apparel', url: '/apparel' }])

async function loadProducts() {
  products.value = []
  num_pages.value = 1
  breadcrumbs.value = [{ name: 'Apparel', url: '/apparel' }]

  tag.value = ''
  let group = props.group
  const page = props.page
  if (group === 'page') {
    group = 'shop'
  }
  if (!group) {
    group = 'shop'
  }

  if (group == 'bytag') {
    tag.value = props.page
    group = ''
  }
  if (tag.value) {
    const data = await $axios.graphql(gql`query productByTagQuery($tag: String!){
    sellProducts(byTag: $tag) {
      name
      slug: url
      price
      is_new
      is_on_special
      image(image_aspect: "4x3", image_width: 300) {
        url
        width
        height
      }
    }
  }`, {
      tag: tag.value,
    })
    products.value = new SellProduct().map(data.sellProducts)
  } else {
    if (group) {
    // Need the group to get heading etc
      groupDetails.value = await new CognitoGroup().findByUrl('Sell\\Product', group)
    } else {
      groupDetails.value = await new CognitoGroup().getTopLevelGroup('Sell\\Product')
    }

    if (!groupDetails.value.isRoot()) {
      parentGroups.value = await groupDetails.value.getParents()
      parentGroups.value.forEach((parentGroup) => {
        if (parentGroup.isRoot()) {
          return
        }
        if (parentGroup.url == 'shop') {
          return
        }
        breadcrumbs.value.push({ name: parentGroup.name, url: parentGroup.url })
      })
      breadcrumbs.value.push({ name: groupDetails.value.name, url: group })
    }

    childGroups.value = await groupDetails.value.getChildren(1)

    const data = await new SellProduct().find_many({
      image_aspect: '4x3',
      image_width: 300,
      page_size: 50,
      page,
      group,
    })
    products.value = data.data
    num_pages.value = data.num_pages
  }
}
watch(() => props, () => {
  loadProducts()
}, {
  deep: true,
})
onMounted(() => {
  loadProducts()
})
onServerPrefetch(async () => {
  await loadProducts()
})
</script>
