<template>
  <div v-if="widget.outer == 'core/heading'">
    <page-builder-heading :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'core/hero_quotex'">
    <page-builder-hi-octane-heading :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'core/form'">
    <page-builder-contact :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Tpl/headline_event'">
    <page-builder-tpl-headline-event :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Event/venue_list'">
    <page-builder-event-venue-list :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Sell/product_sidebar'">
    <page-builder-tpl-sidebar :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Sell/product_tag_cloud'">
    <page-builder-tag-cloud :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Sell/product_grid'">
    <page-builder-tpl-shop :templatevar="widget.templatevar" :group="props.urlParts.item_url" :page="props.urlParts.url_parameter_2" />
  </div>
  <div v-else-if="widget.outer == 'Sell/product_view'">
    <page-builder-tpl-sell-product-view :templatevar="widget.templatevar" :product="props.urlParts.item_url" />
  </div>
  <div v-else-if="widget.outer == 'Event/upcoming_list'">
    <page-builder-event-upcoming-list :templatevar="widget.templatevar" :group="props.urlParts.item_url" :page="props.urlParts.url_parameter_2" />
  </div>
  <div v-else-if="widget.outer == 'Event/3_small_upcoming_list'">
    <page-builder-event-3-small-upcoming-list :templatevar="widget.templatevar" :group="props.urlParts.item_url" :page="props.urlParts.url_parameter_2" />
  </div>
  <div v-else-if="widget.outer == 'Event/view'">
    <page-builder-event-view :templatevar="widget.templatevar" :event="props.urlParts.item_url" :page="props.urlParts.url_parameter_2" />
  </div>
  <div v-else-if="widget.outer == 'Event/merch_list'">
    <page-builder-event-merch-list :templatevar="widget.templatevar" />
  </div>
  <cgn-page-builder v-else :widget="widget" :url-parts="urlParts" :contained-class="props.containedClass" />
</template>

<script setup lang="ts">
import { CognitoUrlParts } from '~cognito/models/Cognito/Page'

const props = defineProps({
  widget: {
    required: true,
  },
  containedClass: {
    type: String,
    default: 'px-6 py-2 mx-auto max-w-5xl',
  },
  urlParts: {
    type: CognitoUrlParts,
    required: true,
  },
})
</script>
