<template>
  <div class="mx-auto max-w-7xl py-12 lg:py-0">
    <div
      class="
          mx-4 flex
          h-full
          flex-col
          items-stretch
          justify-center
          lg:flex-row
        "
    >
      <div class="hidden w-1/3 justify-center py-5 text-gray-600 lg:flex">
        <i-circum:mail class="h-32 w-32" />
      </div>
      <div class="prose max-w-none px-5 text-xl prose-p:my-2 lg:w-full lg:pt-5">
        <div v-if="props.templatevar.heading">
          {{ props.templatevar.heading }}
        </div>
        <div v-html="props.templatevar.content" />
      </div>
      <div
        class="
            flex
            w-full
            flex-col
            justify-center
            p-5
            lg:w-full
          "
      >
        <form
          class="text-lg font-semibold"
          @submit.prevent="sendContactForm()"
        >
          <div class="flex gap-4">
            <cgn-form-input-text
              v-model="formValues.name"
              label="Your Name"
              required
              class="w-full"
            />
            <cgn-form-input-email
              v-model="formValues.email"
              label="Your Email"
              class="w-full"
              required
            />
          </div>
          <cgn-form-input-text
            v-model="formValues.subject"
            label="Subject"
            class="mt-4"
            required
          />
          <cgn-form-input-textarea
            v-model="formValues.message"
            label="Your Message"
            class="mt-4"
            required
          />
          <cgn-spinner v-if="is_loading" />
          <div v-else class="mt-3">
            <cgn-alert-danger v-if="submission_error">
              {{ submission_error }}
            </cgn-alert-danger>
            <cgn-alert-success v-if="submitted_ok">
              Thank you for your message.
            </cgn-alert-success>
          </div>
          <cgn-button fullwidth color-brand>
            Submit
          </cgn-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CognitoFormSubmit } from '~cognito/models/Cognito/FormSubmit'
import { getUser } from '~cognito/plugins/axios'

class Templatevars {
  heading?: string
  content?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})

const userStore = useUserStore()

const formValues = ref({
  name: '',
  email: '',
  subject: '',
  message: '',
})

const is_loading = ref(false)
const submitted_ok = ref(false)
const submission_error = ref('')

function sendContactForm() {
  is_loading.value = true
  new CognitoFormSubmit({
    details: formValues.value,
  }).save()
    .then((data) => {
      submitted_ok.value = data.success
      submission_error.value = data.error
      is_loading.value = false
    })
}

onMounted(async () => {
  await getUser()
  formValues.value.name = `${userStore.user.first_name} ${userStore.user.last_name}`
  formValues.value.email = userStore.user.email
})
</script>
