<template>
  <cgn-router-view />
</template>

<script setup lang="ts">
useSchemaOrg([
  // @todo Select Identity: https://unhead-schema-org.harlanzw.com//guide/guides/identity
  defineOrganization({
    name: 'Hi-Octane Motorsport and Photography',
    logo: () => usePagesStore().currentDomain.favicon.url,
  }),
  defineWebSite({
    name: 'Hi-Octane Motorsport and Photography',
  }),
  defineWebPage(),
])
</script>
