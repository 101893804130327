<template>
  <div class="flex items-center gap-5 px-2 pt-4">
    <div class="h-px grow bg-secondary-500" />
    <div class="shrink text-center font-title text-2xl text-black">
      {{ templatevar.heading }}
    </div>
    <div class="h-px grow bg-secondary-500" />
  </div>
</template>

<script lang="ts">
class Templatevars {
  heading?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
</script>
