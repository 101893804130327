<template>
  <div class="mx-auto grid max-w-sm grid-cols-1 gap-8 p-4 sm:max-w-6xl sm:grid-cols-3">
    <router-link v-for="event in events" :key="event.id" :to="event.can_enter ? `/event/${event.url}` : ''" class="flex flex-col overflow-hidden" :class="{ 'pointer-events-none': !event.can_enter }">
      <div class="aspect-square border border-secondary-400">
        <cgn-lazy-image :image="event.type.photo" class="w-full" />
      </div>
      <div class="flex flex-1 flex-col justify-between text-center">
        <div class="font-title text-2xl font-bold sm:text-3xl">
          {{ event.name }}
        </div>
        <div class="font-title text-lg sm:text-xl">
          <div>
            {{ event.start_date.format('do MMMM Y') }}
          </div>
          <div class="pb-2 font-title text-xl font-semibold text-brand-500">
            {{ event.venue.name }}
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script lang="ts">
import { gql } from 'graphql-request'
import { $axios } from '~cognito/plugins/axios'
import { EventEvent } from '~cognito/models/Event/Event'

class Templatevars {
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
const events = ref<EventEvent[]>([])

async function loadEvents() {
  const data = await $axios.graphql(gql`
  {
  eventEvents(first: 3, isFuture: true) {
    id
    name
    url
    can_enter
    type {
      photo(image_aspect: "1x1", image_width: 600) {
        url
        width
        height
      }
    }
    start_date
    venue {
      name
    }
  }
  }
  `)
  events.value = new EventEvent().map(data.eventEvents)
}
onMounted(() => {
  loadEvents()
})
onServerPrefetch(async () => {
  await loadEvents()
})
</script>
