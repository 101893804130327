<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2150 750">
    <path d="M1741.69 733.96H366.924C164.249 733.96-.053 569.657-.053 366.983S164.249.005 366.924.005H1741.69c202.675 0 366.977 164.303 366.977 366.977S1944.365 733.96 1741.69 733.96" fill="#bce7d1" />
    <g fill="#100f0d">
      <path d="M1797.288 279.318l-46.079-26.441-46.732-26.814c-30.973-17.773-69.598 4.589-69.598 40.303v6.019c0 3.293 1.773 6.335 4.627 7.973l21.679 12.441c6.013 3.451 13.52-.892 13.52-7.826v-14.254c0-7.058 7.626-11.477 13.746-7.965l42.719 24.507 42.586 24.434c6.147 3.529 6.147 12.401 0 15.929l-85.305 48.943c-6.12 3.511-13.746-.908-13.746-7.966v-7.081c0-35.714-38.612-58.076-69.598-40.303l-92.811 53.255c-31.119 17.857-31.119 62.75 0 80.606l92.811 53.255c30.986 17.773 69.598-4.591 69.598-40.303v-6.033a9.21 9.21 0 0 0-4.627-7.974l-21.679-12.44c-6.013-3.452-13.52.891-13.52 7.826v14.269c0 7.057-7.626 11.477-13.746 7.964l-85.305-48.94c-6.147-3.528-6.147-12.401 0-15.93l85.305-48.941c6.12-3.512 13.746.907 13.746 7.965v7.081c0 35.714 38.626 58.077 69.598 40.303l92.811-53.253c31.133-17.857 31.133-62.749 0-80.606m-302.659 8.823l-108.091 223.074h-44.852l40.386-83.362-63.496-139.717h46.003l40.799 93.563 44.559-93.563h44.692M415.176 446.554V425.96c-11.725 14.258-29.15 23.129-50.057 23.129-43.407 0-76.353-34.851-76.353-81.742 0-46.57 34.215-82.057 77.302-82.057 20.275 0 37.383 8.874 49.108 22.813v-19.96h38.967v158.409zm-.319-79.207c0-26.61-19.325-45.304-43.087-45.304s-43.087 19.01-43.087 45.304c0 25.981 19.325 45.308 43.087 45.308s43.087-18.693 43.087-45.308" />
      <path d="M643.424 411.381c-13.624 0-17.424-5.068-17.424-18.376v-70.334h25.026v-34.532H626v-38.651h-39.92v38.651h-51.427v-15.765c0-13.306 5.068-18.378 19.01-18.378h8.766v-30.733h-19.222c-32.949 0-48.476 10.774-48.476 43.723v21.153h-22.177v34.532h22.177V446.55h39.922V322.672h51.427v77.621c0 32.319 12.354 46.258 44.671 46.258h20.593v-35.168h-7.92m63.678-33.574c2.853 23.442 19.644 36.751 40.872 36.751 16.79 0 29.781-7.924 37.383-20.595h40.87c-9.502 33.586-39.603 55.128-79.203 55.128-47.84 0-81.425-33.582-81.425-81.422s35.486-82.378 82.375-82.378c47.207 0 81.425 34.852 81.425 82.378 0 3.484-.32 6.97-.955 10.138zm79.522-24.715c-2.851-20.591-19.642-32.949-39.286-32.949s-35.799 12.04-39.919 32.949h79.205m171.192 158.125V288.144h38.97v20.591c11.722-14.573 29.149-23.445 50.057-23.445 42.772 0 76.354 35.168 76.354 81.742s-34.216 82.058-77.303 82.058c-19.96 0-36.434-7.921-47.84-21.227v83.359zm125.462-143.876c0-25.662-19.328-45.307-43.087-45.307s-43.09 19.012-43.09 45.307c0 25.979 19.328 45.307 43.09 45.307s43.087-19.644 43.087-45.307m180.664 79.211V425.96c-11.722 14.258-29.147 23.129-50.057 23.129-43.406 0-76.354-34.851-76.354-81.742 0-46.57 34.216-82.057 77.302-82.057 20.277 0 37.387 8.874 49.109 22.813v-19.96h38.97v158.409zm-.319-79.207c0-26.61-19.322-45.304-43.087-45.304-23.759 0-43.087 19.01-43.087 45.304 0 25.981 19.328 45.308 43.087 45.308 23.765 0 43.087-18.693 43.087-45.308" />
      <path d="M887.591 303.664s9.914-18.377 34.215-18.377c10.39 0 17.113 3.577 17.113 3.577v40.406s-14.662-9.056-28.125-7.229-21.978 14.185-21.937 30.73v93.779h-40.235V288.14h38.968v15.525" />
    </g>
  </svg>
</template>
