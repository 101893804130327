import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://hioctane.${config.devbase}`
config.prodURL = 'https://cms.hi-octane.com.au'
config.siteURL = 'https://hi-octane.com.au'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.group_args.image_aspect = '16x6'

config.isEcommerce = false
config.profile.edit_date_of_birth = true
config.profile.edit_drivers_licence = true
config.profile.edit_emergency_contact = true
config.sentryDsn = 'https://14b35988439443d79da704a9ab109661@glitchtip.logger.jm1.au/10'

export { config }
