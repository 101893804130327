<template>
  <div v-if="has_payment" class="mx-auto my-2 w-full max-w-2xl">
    <cgn-alert-success v-if="payment_ok">
      Payment Successful
      Please check your email for your receipt
    </cgn-alert-success>
    <cgn-alert-danger v-else>
      Payment Failed
    </cgn-alert-danger>
  </div>
  <cgn-spinner v-else-if="loading" />
  <div v-else-if="calculatedPrice">
    <div class="relative h-[300px] sm:h-[500px]">
      <div class="absolute inset-0 bg-cover bg-center" :style="{ 'background-image': `url(${eventDetails.type.photo?.url})` }" />
    </div>
    <div class="text-center text-4xl font-bold">
      {{ eventDetails.name }}
    </div>
    <div class="text-center text-2xl font-semibold">
      {{ eventDetails.venue.name }}
    </div>
    <div class="text-center text-2xl font-semibold">
      {{ eventDetails.start_date.toHumanDateString(true) }}
    </div>
    <div v-if="eventDetails.start_date.toHumanTimeString() != '12:00am'" class="text-center text-2xl font-semibold">
      {{ eventDetails.start_date.toHumanTimeString() }}
    </div>
    <div class="mx-auto max-w-6xl p-2">
      <div class="prose max-w-none" v-html="eventDetails.page_content" />
      <template v-if="!isLoggedIn()">
        <cgn-button color-info fullwidth class="mt-5" @click="dologin">
          Log in to purchase
        </cgn-button>
      </template>
      <template v-else>
        <div class="prose max-w-none" v-html="eventDetails.entrant_content" />
        <form v-if="showUpdateProfile" class="my-2" @submit.prevent="updateProfile">
          <div class="text-lg text-danger-500">
            If you wish to enter, you need to complete the below
          </div>
          <cgn-form-input-date-of-birth
            v-model="profileForm.date_of_birth"
            label="Date of Birth"
            required
          />
          <cgn-form-input-text
            v-model="profileForm.drivers_licence_number"
            label="Drivers Licence Number"
            required
          />
          <cgn-form-input
            v-model="profileForm.drivers_licence_expiry"
            type="date"
            label="Drivers Licence Expiry"
            required
          />
          <cgn-alert-warning v-if="licenceExpiryWarning">
            Your licence expiry must be in the future
          </cgn-alert-warning>
          <cgn-form-dropdown
            v-model="profileForm.drivers_licence_state_of_issue_id"
            :options="states"
            label="Drivers Licence State of Issue"
            required
          />

          <cgn-form-input-text
            v-model="profileForm.emergency_contact_name"
            label="Emergency Contact Name"
            required
          />
          <cgn-form-input-phone
            v-model="profileForm.emergency_contact_phone"
            label="Emergency Contact Phone"
            required
          />
          <cgn-button color-brand fullwidth class="font-bold">
            Save
          </cgn-button>
          <cgn-button is-label fullwidth color-info class="mt-3 font-bold" @click="showUpdateProfile = false">
            I want to spectate
          </cgn-button>
        </form>
        <form v-else @submit.prevent="submitEntry">
          <div v-if="eventDetails.can_enter && entryForm.entryCategoryRadio.length > 1" class="font-bold">
            <cgn-form-radio-button v-model="entryForm.category_id" :options="entryForm.entryCategoryRadio" />

            <div v-if="selectedCategory">
              <div v-if="selectedCategory.is_entry_transfer">
                <cgn-form-input v-model="entryForm.entry_transfer_from_name" label="Name of Person your are transferring ticket from" required />
                <cgn-form-input v-model="entryForm.entry_transfer_from_invoice" type="number" label="Invoice Number of the ticket being transferred" required />
              </div>
              <cgn-form-address-selector
                v-model="entryForm.address_id"
                v-model:addresses="addresses"
                here-api-key="GOBLSSIkkrgjhMahFmXPramj-95rVXZYpj-0pj7DsFU"
              />

              <cgn-form-dropdown
                v-model="entryForm.vehicle_id"
                label="Vehicle"
                prompt="New Vehicle"
                :options="entryForm.vehicles.map(e => { return { id: e.id, name: `${e.make} ${e.model} ${e.colour} ${e.registration}` } })"
                class="w-full"
                required
              />
              <cgn-vehicle-edit v-if="!entryForm.vehicle_id || (!selectedVehicle?.photo?.url && eventDetails.require_vehicle_photo) || (selectedVehicle?.registrationHasExpired() && selectedCategory?.vehicle_must_be_registered)" v-model="entryForm.vehicle_id" v-model:vehicles="entryForm.vehicles" no-scroll :require-photo="eventDetails.require_vehicle_photo" :require-registration="selectedCategory?.vehicle_must_be_registered ?? true" />
              <div v-if="!selectedVehicle?.photo?.url && eventDetails.require_vehicle_photo">
                Vehicle Photo is required
                <input type="readonly" class="size-0" required>
              </div>
              <div v-if="selectedVehicle?.registrationHasExpired() && selectedCategory?.vehicle_must_be_registered">
                <cgn-alert-danger>
                  The vehicle registration has expired. Please update it.
                  <input type="readonly" class="size-0" required>
                </cgn-alert-danger>
              </div>
              <div v-if="entryForm.extras">
                <div class="pt-4 text-center text-2xl">
                  Add to your entry
                </div>
                <div v-for="extra in entryForm.extras" :key="extra.id">
                  <label class="block cursor-pointer rounded-lg border-2 border-gray-300 bg-white px-6 py-4 shadow-sm dark:border-gray-700 dark:bg-slate-600 sm:flex sm:justify-between">
                    <div class="cgn-radio-button text-sm">
                      <div class="flex items-center">
                        <input v-if="!extra.disabled" v-model="extra.checked" type="checkbox" class="mr-2">
                        <div>
                          {{ extra.label }}
                          ${{ extra.price.toFixed(2) }}
                        </div>
                      </div>
                      <div v-if="extra.disabled" class="cgn-radio-button-content text-xs">
                        Sorry Sold out
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <cgn-alert-danger v-else>
            You cannot enter this event
            <span v-if="eventDetails.entries_open_at.isFuture()">
              -
              Entries open
              {{ eventDetails.entries_open_at.toHumanDateString(false) }}
              {{ eventDetails.entries_open_at.toHumanTimeString() }}
            </span>
          </cgn-alert-danger>
          <div v-if="entryForm.showLicencePurchase()">
            <div class="pt-4 text-center text-2xl font-bold">
              Licence
            </div>
            <cgn-form-radio-button v-model="entryForm.licence_id" :options="entryForm.licenceTypeOptions(selectedCategory?.race_licence_included_in_entry_fee)" />
            <div v-if="entryForm.licence_id == 0 && entryForm.category_id != 0">
              <cgn-form-input v-model="entryForm.race_licence_number" :label="entryForm.raceLicenceName()" required />
              <cgn-form-input v-model="entryForm.race_licence_expiry" type="date" label="Licence Expiry" required />
              <template v-if="entryForm.race_licence_expired_by_event()">
                <cgn-form-checkbox label="I will renew my licence prior to the event" required />
                <cgn-alert-warning>
                  You will be required to renew your licence prior to the event to be eligible to participate
                </cgn-alert-warning>
              </template>
            </div>
            <div v-if="selectedLicence?.is_annual">
              <div class="pt-4 text-center text-2xl">
                Medical Statement
              </div>
              <cgn-form-dropdown v-model="entryForm.blood_type_id" :options="selectedLicence.blood_types" label="Blood Type" required />
              <cgn-form-input v-model="entryForm.date_of_last_tetanus_vaccination" type="date" label="Tetanus immunisation date" required />
              <cgn-form-tristate v-model="entryForm.has_allergies" label="Allergies" required />
              <cgn-form-tristate v-model="entryForm.has_had_psychiatric_psychological_illness" label="Have You ever been diagnosed as having/or had treatment for a Psychiatric or Psychological illness" required />
              <cgn-form-tristate v-model="entryForm.has_had_brain_injury" label="Persistent or severe headache, head injury, epilepsy, seizure or loss of consciousness?" required />
              <cgn-form-tristate v-model="entryForm.has_had_heart_issues" label="Heart or lung disease, including infection, blood vessel disease, hypertension, coronary bypass, angioplasty or other surgical procedure?" required />
              <cgn-form-tristate v-model="entryForm.has_had_cancer_diabeties" label="Cancer, diabetes, kidney, liver, thyroid, gastrointestinal, blood pressure disorders, including any associated surgical procedures?" required />
              <cgn-form-tristate v-model="entryForm.has_had_significant_illness_or_injury" label="Any other significant illness, injury or surgery not already noted?" required />
              <cgn-form-tristate v-model="entryForm.is_on_medication" label="Have you taken any medications, including self-medication or alternative therapies?" required />
              <cgn-form-tristate v-model="entryForm.has_hearing_impairment" label="Do you have any hearing impairment or loss?" required />
              <cgn-form-tristate v-model="entryForm.has_hearing_disorder" label="Do you suffer from any hearing disorder including tinnitus?" required />
              <cgn-form-tristate v-model="entryForm.is_normal_eyesight" label="Is your eyesight normal in both eyes for distance vision?" required />
              <cgn-form-input v-model="entryForm.date_of_last_medical_exam" type="date" label="When did you last have a medical examination?" required />
              <div v-if="selectedLicence.terms_and_conditions">
                <div class="prose max-w-none text-sm" v-html="selectedLicence.terms_and_conditions.content" />
                <cgn-form-checkbox label="I have read and accept the Terms & Conditions" required />
              </div>
            </div>
          </div>

          <template v-if="canHireGarage">
            <div class="pt-4 text-center text-2xl font-bold">
              Garage Hire
            </div>
            <cgn-form-radio-button
              v-if="!selectedCategory?.requires_garage" v-model="want_a_garage" :options="[
                {
                  name: 'I do not need a Garage / Carport',
                  id: 0,
                },
                {
                  name: 'I want to hire a Garage / Carport',
                  id: 1,
                },
              ]"
            />
            <div v-if="showGaragePicker" class="my-2">
              <cgn-map-site-picker-leaflet
                v-model="entryForm.garage_id"
                :image="eventDetails.garage_map"
                :sites="entryForm.garageTypeMap"
                selected-colour="#5555ff"
                unselected-colour="#aaffaa"
                unavailable-colour="#ffaaaa"
              />
            </div>
          </template>
          <template v-if="eventDetails.offer_camp_sites && eventDetails.camp_sites.length > 0">
            <div class="pt-4 text-center text-2xl font-bold">
              Camping
            </div>
            <cgn-form-radio-button
              v-model="want_to_camp" :options="[
                {
                  name: 'I do not need a Camp Site',
                  id: 0,
                },
                {
                  name: 'I want to Camp',
                  id: 1,
                },
              ]"
            />
            <div v-if="want_to_camp" class="my-2">
              <cgn-map-site-picker-leaflet
                v-model="entryForm.camp_site_id"
                :image="eventDetails.camp_site_map"
                :sites="campSiteTypeMap"
                selected-colour="#5555ff"
                unselected-colour="#aaffaa"
                unavailable-colour="#ffaaaa"
              />
            </div>
          </template>

          <div v-if="entryForm.spectators.length && eventDetails.can_buy_spectator_ticket" class="font-bold">
            <div class="pt-4 text-center text-2xl">
              Spectate
            </div>
            <div class="prose" v-html="eventDetails.spectator_content" />
            <div class="divide-y overflow-hidden rounded-lg border">
              <div v-for="(spectator, index) in entryForm.spectators" :key="spectator.id" :class="index % 2 ? 'bg-gray-100' : 'bg-gray-50'" class="px-2 py-1">
                <cgn-form-input v-if="!spectator.disabled" v-model="spectator.qty" type="number" min-amount="0" :max-amount="spectator.qty_available > 0 ? spectator.qty_available : 100" :label="`${spectator.label} $${spectator.price.toFixed(2)}`" />
                <div v-else class="py-4 text-lg">
                  {{ spectator.label }} - Sold out
                </div>
              </div>
            </div>
            <cgn-form-address-selector
              v-model="entryForm.address_id"
              v-model:addresses="addresses"
              here-api-key="GOBLSSIkkrgjhMahFmXPramj-95rVXZYpj-0pj7DsFU"
            />
          </div>

          <div v-if="eventDetails.products.length" class="font-bold">
            <div class="pt-4 text-center text-2xl">
              Merch
            </div>
            <div class="prose max-w-none" v-html="eventDetails.merch_content" />
            <div class="space-y-2">
              <div v-for="product in eventDetails.products" :key="product.id" class="overflow-hidden rounded-md border">
                <div class="flex flex-col sm:flex-row">
                  <div class="sm:w-1/4">
                    <cgn-lazy-image :image="product.photos[0]" class="w-full" />
                  </div>
                  <div class="flex flex-col p-2 sm:w-3/4">
                    <div class="text-3xl">
                      {{ product.name }}
                    </div>
                    <div v-if="product.selectedOption" class="text-2xl">
                      ${{ product.options.find(e => e.id == product.selectedOption)?.price.toFixed(2) }}
                    </div>
                    <div v-if="product.description" class="pt-2">
                      {{ product.description }}
                    </div>
                    <div v-if="product.options.length > 0">
                      <cgn-form-dropdown v-model="product.selectedOption" label="Option" :options="product.options" />
                    </div>
                    <div class="flex h-full items-end">
                      <cgn-button v-if="product.selectedOption" color-brand is-label fullwidth @click="addProductToOrder(product)">
                        Add to order
                      </cgn-button>
                      <cgn-button v-else color-brand is-label fullwidth disabled>
                        Select an option
                      </cgn-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="eventDetails.merch.length" class="font-bold">
            <div class="pt-4 text-center text-2xl">
              Merch
            </div>
            <div class="space-y-2">
              <div v-for="merch in eventDetails.merch" :key="merch.id" class="overflow-hidden rounded-md border">
                <div class="flex flex-col sm:flex-row">
                  <div class="sm:w-1/4">
                    <cgn-lazy-image :image="merch.photos[0]" class="w-full" />
                  </div>
                  <div class="flex flex-col p-2 sm:w-3/4">
                    <div class="text-3xl">
                      {{ merch.name }}
                    </div>
                    <div class="text-2xl">
                      ${{ merch.price_each.toFixed(2) }}
                    </div>
                    <div v-if="merch.description" class="pt-2">
                      {{ merch.description }}
                    </div>
                    <div v-if="merch.options.length > 0">
                      <cgn-form-dropdown v-model="merch.selectedOption" label="Option" :options="merch.options" />
                    </div>
                    <div class="flex h-full items-end">
                      <cgn-button v-if="merch.options.length == 0 || merch.selectedOption" color-brand is-label fullwidth @click="addMerchToOrder(merch)">
                        Add to order
                      </cgn-button>
                      <cgn-button v-else color-brand is-label fullwidth disabled>
                        Select an option
                      </cgn-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedCategory" class="my-2 rounded-md border p-2">
            <div class="h-[300px] overflow-scroll">
              <div class="prose max-w-none pb-4" v-html="eventDetails.type.terms_and_conditions.content" />
            </div>
            <cgn-form-checkbox label="I have read and accept the Terms & Conditions" class="font-bold" required />
          </div>
          <div class="py-2 font-bold">
            <div v-if="selectedCategory" class="my-2 rounded-md border p-2 text-sm">
              <div class="text-xl">
                {{ selectedCategory.name }} ${{ selectedCategory.price.toFixed(2) }}
              </div>
              <div v-if="selectedAddress">
                Address: {{ selectedAddress.street_address }}
              </div>
              <div v-if="selectedVehicle">
                Vehicle: {{ selectedVehicle.make }} {{ selectedVehicle.model }}
              </div>
              <div v-if="selectedCategory.ticket_type && selectedCategory.spectator_tickets_per_entry > 0">
                <div>
                  1 x Driver entry ( {{ selectedCategory.ticket_type }} )
                </div>
                <div v-if="selectedCategory.spectator_tickets_per_entry > 1">
                  {{ selectedCategory.spectator_tickets_per_entry - 1 }} x Crew entry ( {{ selectedCategory.ticket_type }} )
                </div>
              </div>
            </div>
            <div v-for="extra in entryForm.extras.filter(e => e.checked == true)" :key="extra.id" class="my-2 flex items-center justify-between rounded-md border px-2 py-1 text-lg">
              {{ extra.label }} ${{ extra.price.toFixed(2) }}
            </div>
            <div v-if="entryForm.licence_id" class="my-2 flex items-center justify-between rounded-md border px-2 py-1 text-lg">
              {{ selectedLicence.name }}
              <template v-if="selectedCategory?.race_licence_included_in_entry_fee">
                (included)
              </template>
              <template v-else>
                ${{ selectedLicence.price.toFixed(2) }}
              </template>
            </div>
            <div v-for="spectator in entryForm.spectators.filter(e => e.qty > 0)" :key="spectator.id" class="my-2 flex items-center gap-2 rounded-md border px-2 py-1">
              <div class="min-w-max text-xs">
                <div class="text-lg">
                  {{ spectator.label }} ${{ (spectator.price * spectator.qty).toFixed(2) }}
                </div>
                <div>
                  Spectator ticket
                </div>
                <div>
                  ${{ spectator.price.toFixed(2) }} each
                </div>
              </div>
              <cgn-form-input v-model="spectator.qty" class="w-full" type="number" min-amount="0" :max-amount="spectator.qty_available > 0 ? spectator.qty_available : 100" />
            </div>
            <div v-for="(merch, index) in entryForm.merch" :key="merch.id" class="my-2 flex items-center justify-between gap-2 rounded-md border px-2 py-1 text-lg">
              <span>{{ merch.name }} ${{ merch.price.toFixed(2) }}</span>
              <div class="cursor-pointer p-2 text-gray-500" @click="entryForm.merch.splice(index, 1)">
                <i-heroicons-solid:x />
              </div>
            </div>
            <div v-for="(sku, index) in entryForm.skus" :key="sku.id" class="my-2 flex items-center justify-between gap-2 rounded-md border px-2 py-1 text-lg">
              <span>{{ sku.name }} ${{ sku.price.toFixed(2) }}</span>
              <div class="cursor-pointer p-2 text-gray-500" @click="entryForm.skus.splice(index, 1)">
                <i-heroicons-solid:x />
              </div>
            </div>
            <div v-if="entryForm.garage_id" class="my-2 flex items-center justify-between gap-2 rounded-md border px-2 py-1 text-lg">
              {{ garageTypeRadio.find(e => e.id == entryForm.garage_id)?.name }}
            </div>
            <div v-if="entryForm.camp_site_id" class="my-2 flex items-center justify-between gap-2 rounded-md border px-2 py-1 text-lg">
              {{ campSiteTypeRadio.find(e => e.id == entryForm.camp_site_id)?.name }}
            </div>
            <div v-if="calculatedPrice.total > 0" class="my-2 rounded-md border p-2 text-2xl">
              Total ${{ calculatedPrice.total.toFixed(2) }}
            </div>
            <div v-if="eventDetails.credit_balance > 0" class="my-2 rounded-md border p-2">
              Credit balance: $ {{ eventDetails.credit_balance.toFixed(2) }}
            </div>
            <div v-if="calculatedPrice.total > 0" class="pt-4 text-center text-2xl font-bold">
              Pay by
            </div>
            <cgn-form-radio-button v-model="entryForm.payment_gateway" :options="eventDetails.payment_methods" />
            <div v-if="surcharge" class="my-2 rounded-md border p-2">
              Admin Processing Fee ${{ surcharge.toFixed(2) }}
            </div>
          </div>
          <cgn-alert-danger v-if="errorMessage" class="font-bold">
            {{ errorMessage }}
          </cgn-alert-danger>
          <cgn-alert-danger v-if="!garageHireOk" class="font-bold">
            Please choose a Garage / Carport to hire
          </cgn-alert-danger>
          <cgn-button v-if="calculatedPrice.total > 0 && entryForm.payment_gateway && garageHireOk" color-brand fullwidth class="font-bold">
            <template v-if="calculatedPrice.payable + surcharge > 0">
              Pay ${{ (calculatedPrice.payable + surcharge).toFixed(2) }} Now
            </template>
            <template v-else>
              Complete Order
            </template>
          </cgn-button>
        </form>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { CognitoPayment } from '~cognito/models/Cognito/Payment'
import type { CognitoAddress } from '~cognito/models/Cognito/Address'
import { EventEvent } from '~cognito/models/Event/Event'
import type { EventMerch } from '~cognito/models/Event/Merch'
import type { EventProduct } from '~cognito/models/Event/Product'
import { useToastStore } from '~cognito/stores/toast'
import { EventEntryForm } from '~cognito/models/Event/EntryForm'
import { $axios, isLoggedIn, setRedirectAfterLogin } from '~cognito/plugins/axios'
import { CognitoState } from '~cognito/models/Cognito/State'
import { CognitoTime } from '~cognito/models/Cognito/Time'

class Templatevars {
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  event: {
    type: String,
    required: true,
  },
  page: {
    type: String,
  },
})

const route = useRoute()
const router = useRouter()
const entryForm = ref<EventEntryForm>(new EventEntryForm())

const selectedCategory = computed(() => {
  return entryForm.value.selectedCategory()
})
const selectedLicence = computed(() => {
  return entryForm.value.selectedLicence()
})

const loading = ref(false)
const eventDetails = ref<EventEvent>(new EventEvent())
const states = ref<CognitoState[]>([])
const userStore = useUserStore()
const profileForm = ref({
  date_of_birth: '',
  drivers_licence_number: '',
  drivers_licence_expiry: '',
  drivers_licence_state_of_issue_id: 0,
  emergency_contact_name: '',
  emergency_contact_phone: '',
})
const errorMessage = ref('')
const has_payment = ref(false)
const want_a_garage = ref(0)
const want_to_camp = ref(0)
const payment_ok = ref(false)

const licenceExpiryWarning = computed(() => {
  if (!profileForm.value.drivers_licence_expiry) {
    return false
  }
  return new CognitoTime(profileForm.value.drivers_licence_expiry).isPast()
})

const submitEntry = async () => {
  errorMessage.value = ''
  const result = await new EventEvent().submitEntry(entryForm.value)
  errorMessage.value = result.message
  if (result.success) {
    window.location = result.redirect
  }
}
const hideGaragePicker = ref(false)
const showGaragePicker = computed(() => {
  if (hideGaragePicker.value) {
    return false
  }
  return want_a_garage.value || selectedCategory.value?.requires_garage
})

const garageHireOk = computed(() => {
  if (!showGaragePicker.value) {
    return true
  }
  return !!entryForm.value.garage_id
})

const addMerchToOrder = (merch: EventMerch) => {
  entryForm.value.addMerchToOrder(merch)
  useToastStore().addToast(`${merch.name} added to order`, 'success', 2500)
}

const addProductToOrder = (product: EventProduct) => {
  entryForm.value.addProductToOrder(product)
  useToastStore().addToast(`${product.name} added to order`, 'success', 2500)
}

const calculatedPrice = computedAsync(async () => {
  return await entryForm.value.calculatedPrice()
})

const surcharge = computed(() => {
  return calculatedPrice.value.surcharges.find(e => e.type == entryForm.value.payment_gateway)?.surcharge ?? 0
})

const addresses = ref<CognitoAddress[]>([])
const selectedAddress = computed(() => {
  return entryForm.value.selectedAddress(addresses.value)
})

const selectedVehicle = computed(() => {
  return entryForm.value.selectedVehicle()
})

const garageTypeRadio = computed(() => {
  entryForm.value.calculateGarageTypeRadio({
    show_booked_by: false,
    price_included_in_entry: selectedCategory.value?.garage_included_in_entry_fee ?? false,
  })
  return entryForm.value.garageTypeRadio
})

watch(() => selectedCategory.value, () => {
  // For some reason the map needs to be re-created
  hideGaragePicker.value = true
  entryForm.value.calculateGarageTypeMap({
    show_booked_by: false,
    price_included_in_entry: selectedCategory.value?.garage_included_in_entry_fee ?? false,
    disabled_types: selectedCategory.value?.unavailable_garage_types,
  })
  nextTick(() => {
    hideGaragePicker.value = false
  })
})

const campSiteTypeRadio = computed(() => {
  entryForm.value.calculateCampSiteTypeRadio({
    show_booked_by: false,
    price_included_in_entry: false,
  })
  return entryForm.value.campSiteTypeRadio
})

const campSiteTypeMap = computed(() => {
  entryForm.value.calculateCampSiteTypeMap({
    show_booked_by: false,
    price_included_in_entry: false,
  })
  return entryForm.value.campSiteTypeMap
})
const canHireGarage = computed(() => {
  return entryForm.value.calculateCanHireGarage({
    category: selectedCategory.value?.id,
  })
})

const showUpdateProfile = ref(true)
const dologin = () => {
  setRedirectAfterLogin(route.fullPath)
  router.replace('/login')
}

const loadData = async () => {
  eventDetails.value = await entryForm.value.loadEvent(props.event)
  entryForm.value.category_id = -1
  profileForm.value = userStore.user
  states.value = await new CognitoState().getAustralianStates()

  // Check profile form
  showUpdateProfile.value = !(
    profileForm.value.date_of_birth
    && profileForm.value.drivers_licence_expiry
    && !licenceExpiryWarning.value
    && profileForm.value.drivers_licence_number
    && profileForm.value.drivers_licence_state_of_issue_id
    && profileForm.value.emergency_contact_name
    && profileForm.value.emergency_contact_phone
  )
}

const updateProfile = async () => {
  await $axios.post('/api/v1/cognito/user/update', profileForm.value)
  loadData()
}
onMounted(async () => {
  loading.value = true
  new CognitoPayment().checkSuccessful()
    .then((data) => {
      has_payment.value = data.has_payment
      payment_ok.value = data.success
    })
  await loadData()
  loading.value = false
})

const jsonld = computed(() => {
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'Event',
    'name': eventDetails.value.name,
    'startDate': eventDetails.value.start_date.toISO8601String(),
    'endDate': eventDetails.value.end_date.toISO8601String(),
    'eventAttendanceMode': 'https://schema.org/OfflineEventAttendanceMode',
    'eventStatus': 'https://schema.org/EventScheduled',
    'location': {
      '@type': 'Place',
      'name': eventDetails.value.venue.name,
      'address': {
        '@type': 'PostalAddress',
        'streetAddress': eventDetails.value.venue.address?.street_address,
        'addressLocality': eventDetails.value.venue.address?.suburb,
        'postalCode': eventDetails.value.venue.address?.postcode,
        'addressRegion': eventDetails.value.venue.address?.state,
        'addressCountry': eventDetails.value.venue.address?.country?.code,
      },
    },
    'image': [
      eventDetails.value.image.url,
    ],
    'description': eventDetails.value.blurb,
    'offers': {
      '@type': 'Offer',
      'url': route.fullPath,
      'price': eventDetails.value.cheapest_price(),
      'priceCurrency': 'AUD',
      'availability': 'https://schema.org/InStock',
      'validFrom': eventDetails.value.entries_open_at.toISO8601String(),
    },
    'organizer': {
      '@type': 'Organization',
      'name': 'Hi-Octane Events',
      'url': 'https://hi-octane.com.au',
    },
  })
})

useHead({
  script: [
    {
      hid: 'breadcrumbs-json-ld',
      type: 'application/ld+json',
      textContent: jsonld,
    },
  ],
})
</script>
